export const getMetaTags = (metadataBlock) => {
  const tags = [];
  if (metadataBlock?.title_tag) {
    tags.push({ hid: 'og:title', property: 'og:title', content: metadataBlock.title_tag });
    tags.push({ hid: 'twitter:title', name: 'twitter:title', content: metadataBlock.title_tag });
  }

  if (metadataBlock?.meta_description) {
    tags.push({ hid: 'description', name: 'description', content: metadataBlock.meta_description });
    tags.push({
      hid: 'og:description',
      property: 'og:description',
      content: metadataBlock.meta_description,
      tagPriority: 'critical',
    });
    tags.push({ hid: 'twitter:description', name: 'twitter:description', content: metadataBlock.meta_description });
  }

  if (metadataBlock?.social_media?.show_social_media && metadataBlock?.social_media?.image_url) {
    tags.push({
      hid: 'og:image',
      property: 'og:image',
      content: metadataBlock.social_media.image_url,
      tagPriority: 'critical',
    });
    tags.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: metadataBlock.social_media.image_url,
      tagPriority: 'critical',
    });

    const fileType = getFileTypeFromUrl(metadataBlock.social_media.image_url);
    if (fileType) {
      tags.push({ hid: 'og:image:type', property: 'og:image:type', content: fileType, tagPriority: 'critical' });
    }
  }

  if (metadataBlock?.keywords) {
    tags.push({ hid: 'keywords', name: 'keywords', content: metadataBlock.keywords?.join(', ') });
  }

  return tags;
};

export function getTitlePropByMetadata(metadataBlock, defaultTitle) {
  const titleTag = metadataBlock?.title_tag ? { titleTemplate: metadataBlock.title_tag } : { title: defaultTitle };
  return titleTag;
}

export const getMetadataAndTags = (metadata, featureType, defaultTitle) => {
  if (!metadata) return { metaTags: [] };

  const metadataBlock = metadata?.find((block) => block.feature_type === featureType) || {};
  const metaTags = getMetaTags(metadataBlock);
  const metaTitle = getTitlePropByMetadata(metadataBlock, defaultTitle);
  return { metaTags, metaTitle };
};

export const getFileTypeFromUrl = (url) => {
  const fileName = url.split('/').pop();
  const fileExtension = fileName?.split?.('.')?.pop()?.toLowerCase();

  const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    webp: 'image/webp',
    svg: 'image/svg+xml',
  };

  return mimeTypes[fileExtension] || '';
};
